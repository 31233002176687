<!--
 * @Author: ChenYaJin
 * @Date: 2023-11-06 17:40:05
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-15 10:36:45
 * @Description: 侧边栏
-->
<template>
  <el-aside :class="'layout-aside-' + config.layout.layoutMode">
    <Logo v-if="config.layout.menuShowTopBar" />
    <MenuVertical />
  </el-aside>
</template>

<script setup lang="ts">
  import Logo from './logo.vue'
  import MenuVertical from './menuVertical.vue'
  import { useConfig } from '~/stores/config'

  defineOptions({
    name: 'AdminAside',
  })

  const config = useConfig()

  const menuWidth = computed(() => config.menuWidth())
</script>

<style scoped lang="scss">
  .layout-aside-Default {
    @apply overflow-hidden;
    background: var(--ba-bg-color-overlay);
    box-shadow: var(--el-box-shadow-light);
    transition: width 0.3s ease;
    width: v-bind(menuWidth);
  }
</style>
